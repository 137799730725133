import _ from 'lodash';
import { Link, PageSeo, ListItem, TopItem } from '@/components';
import { Post } from '@/core/models';
import { API } from '@/core/api';
import config from '@/config';

import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const MAX_DISPLAY = 6;

const Home = ({ posts }: { posts: Post[] }) => {
	const [first, ...lastPosts] = posts;
	const { t } = useTranslation('common');

	return (
		<>
			<PageSeo
				title={`${config.title} - ${config.author}`}
				description={config.description}
				url={config.siteUrl}
			/>
			<div className='divide-y divide-gray-200 dark:divide-gray-700'>
				<TopItem post={first} />
				<ListItem posts={lastPosts} />
			</div>
			{posts.length > MAX_DISPLAY && (
				<div className='flex justify-end text-base font-medium leading-6'>
					<Link
						href='/blog'
						className='text-blue-500 hover:text-blue-600 dark:hover:text-blue-400'
						aria-label='all posts'
					>
						{t('all-posts')} &rarr;
					</Link>
				</div>
			)}
		</>
	);
};

export const getStaticProps = async ({ locale }) => {
	const apiRef = new API();
	const posts = await apiRef.getPosts();
	// console.log(posts);
	return {
		props: {
			posts,
			...(await serverSideTranslations(locale, ['common'])),
		},
		revalidate: 1,
	};
};

export default Home;
